.canvas29985 {
    width: 100%;
    height: 752px;
    display: flex;
    position: relative;
    max-width: 100%;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    margin: 20px;
    margin-left: 100px;
  }
  .Group14291011 {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 752px;
    display: flex;
    position: absolute;
    max-width: 1337px;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
  }
  .image {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 752px;
    position: absolute;
    object-fit: cover;
    border-color: rgba(0, 0, 0, 0.30000001192092896);
    border-style: solid;
    border-width: 1px;
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 1);
  }
  .titoloprogetto291014 {
    top: 356px;
    left: 454px;
    width: 429px;
    height: 39px;
    display: flex;
    position: absolute;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
  }
  .text {
    top: 0px;
    left: 0px;
    color: rgba(43, 43, 43, 1);
    width: 429px;
    height: 39px;
    position: absolute;
    font-size: 40px;
    align-self: auto;
    font-style: normal;
    text-align: left;
    font-family: Nunito;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
  }