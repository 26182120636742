.canvas29985 {
  width: 100%;
  height: 752px;
  display: flex;
  position: relative;
  max-width: 100%;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  margin: 20px;
  margin-left: 40px;
}

.front-end{
  width: 100vw;
  height: 100vh;
  position: absolute;
  object-fit: cover;
  /* border-color: rgba(0, 0, 0, 0.30000001192092896);
  border-style: solid;
  border-width: 1px; */
  background-color: rgba(255, 255, 255, 1);
  background-size: cover;
}

.groupViewePage {
  top: 0px; 
   left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  /* position: absolute; */
  /* max-width: 1337px; */
  box-sizing: border-box;
  align-items: flex-start;
   flex-shrink: 1;
  border-color: transparent;
}


.Group14291011 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 752px;
  display: flex;
  position: absolute;
  max-width: 1337px;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}


.image {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 752px;
  position: absolute;
  object-fit: cover;
  border-color: rgba(0, 0, 0, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 1);
  background-size:cover;
}

.imageColum {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 752px;
  position: absolute;
  object-fit: cover;
  border-color: rgba(0, 0, 0, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 1);
  background-size:cover;
  display: flex;
  flex-direction: row;
}




.wrapper {
  width: 100%;
  height: 20%;
  /* overflow-x: scroll;
  overflow-y: hidden; */
  margin: 0 auto;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(6, auto);
 
}

.wrapper-classic-lateral{
  align-items: center;
 justify-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  /* overflow-y: auto; */
  /* overflow-x: auto;
  overflow-y: scroll; */
  height: 100%;
  width: 20%;
  background-color: white;
  /* overflow-y: unset; */
  /* border-radius: 0 0 40px 40px; */
  overflow: auto;

}

.wrapper-classic {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* overflow-x: auto;
  overflow-y: hidden; */
  height: 20%;
  width: 100%;
  background-color: white;
  /* border-radius: 0 0 40px 40px; */
  overflow: auto;

}

.row-wrapp{
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* overflow-x: auto;
  overflow-y: hidden; */
  height: 20%;
  width: 100%;
}

.row-wrapp-lateral{
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  /* overflow-x: auto;
  overflow-y: scroll; */
  height: 100%;
  width: 20%;
  /* overflow-y: auto; */
  overflow: auto;

}


.wrapper-classic-lateral::-webkit-scrollbar {
  width: 12px;
  color: aqua;
}

.wrapper-classic-lateral::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

.wrapper-classic-lateral::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.item {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid transparent;
  background-position: center;
  width: 180px;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  
  border: 0;
  flex-basis: 33.333%;
  margin-top: 5px;
 
  margin-left: 21px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  bottom: 10;
}

.itemSelected {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid transparent;
  background-position: center;
  width: 180px;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  
  border: 0;
  flex-basis: 33.333%;
  margin-top: 5px;
 
  margin-left: 21px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  bottom: 10;
}

/* .item .image{
  width: 190px;
  position: center;
} */

.itemClassic {
  width: 180px;
  height: 120px;
  border: 0;
  flex-basis: 33.333%;
  margin-top: 5px;
  margin-left: 21px;
  bottom: 10;
}

.itemClassic .image_plane{
  width: 180px;
  /* height: 120px; */
}

.itemClassicSelected {
  width: 180px;
  height: 120px;
  border: 0;
  flex-basis: 33.333%;
  margin-top: 5px;
  margin-left: 21px;
  bottom: 10;
  /* border-bottom: 2px solid black; */
}


.itemClassicSelected .image_plane {
  width: 180px;
  /* height: 120px; */
}

.itemDot .image {
  width: 80px;
}

/* .itemClassic{
  background-color: white;
  background-position: center;
  width: 190px;
  height: 130px;
  margin-bottom: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  bottom: 5;
  border: 0;
  flex-basis: 33.333%;
  margin-left: 21px;
}

.itemSelected {
  
  background-color: white;
  border-radius: 20px;
  border: 1px solid transparent;
  background-position: center;
  width: 190px;
  height: 130px;
  margin-bottom: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
  bottom: 5;
 
  border: 0;
  flex-basis: 33.333%;

  margin-left: 21px;
} */

.itemDot{
  background-color:white;
  border-radius: 320px;
  border: 1px solid transparent;
  background-position: center;
  width: 25px;
  height: 25px;
  background-size: cover;
  background-repeat: no-repeat;
  border: 0;
  flex-basis: 33.333%;
  margin-top: 8px;
  margin-left: 10px;
  margin-right: 10px;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
  bottom: 0;
}


.itemDotSelected{
  background-color:white;
  border-radius: 320px;
  border: 1px solid transparent;
  background-position: center;
  width: 30px;
  height: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  border: 0;
  flex-basis: 33.333%;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4); */
  bottom: 5;
}

.selected-indicator{
  width: 100px;
  height: 2px;
  background-color: black;
  margin: 0 auto;
  margin-top: 5px;
}


@media only screen and (max-width: 980px) {
  .row-wrapp-lateral{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: auto;
    /* overflow-x: auto;
    overflow-y: auto; */
    height: 100%;
    width: 30%;
  }

  .wrapper-classic-lateral{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    /* overflow-x: auto;
    overflow-y: hidden; */
    overflow: auto;
    height: 100%;
    width: 30%;
    background-color: white;
    /* border-radius: 0 0 40px 40px; */
  }

  .itemClassicSelected {
    width: 100%;
    height: 120px;
    border: 0;
    flex-basis: 33.333%;
    margin-top: 5px;
    margin-left: 21px;
    bottom: 10;
    border-bottom: 2px solid black;
    margin-left: 0px;
  }

  .itemClassic {
    width: 100%;
    min-height: 80px;
    border: 0;
    flex-basis: 33.333%;
    /* margin-top: 5px; */
    /* margin-left: 21px; */
    bottom: 10;
  }

  .itemClassicSelected {
    width: 100%;
    min-height: 80px;
    border: 0;
    flex-basis: 33.333%;
    /* margin-top: 5px; */
    /* margin-left: 21px; */
    bottom: 10;
  }
  
  
}