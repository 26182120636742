.container {
    width: 100%;
    background-color: #2c323f;
    padding: 0;
    margin: 0;
    text-align: left;
    color: white;
}
.background-fade-out {
    min-height: 42px;
  }
.image {
    top: 0px;
    left: 0px;
    width: 99%;
    /* height: 175px; */
    /* position: absolute; */
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10000000149011612);
    /* object-fit: cover; */
    /* border-color: transparent; */
    /* background-color: rgba(44, 50, 63, 1); */
  }
  .row-align{
    display: flex;
    flex-direction: row;
    border-color: transparent;
    background-color: #2c323f;
    border-bottom: 1px solid white;
  }

  .Frame1boxautorotate1783 {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 140px; */
    /* display: flex; */
    /* position: absolute; */
    /* box-sizing: border-box; */
    /* align-items: flex-start; */
    /* flex-shrink: 1; */
    /* border-color: transparent; */
  }
  .sottocategorie29203 {
    width: 99%;
    /* min-height: 332px; */
    display: flex;
    position: inline;
    box-sizing: border-box;
    /* align-items: flex-start; */
    /* flex-shrink: 1; */
    border-color: transparent;
    background-color: #2c323f;
    margin: 0;
    padding: 0;
    color: white;
    text-align: left;
    max-height: calc(100vh - 115px);
    /* position: absolute; */
    overflow-y: scroll;
    z-index: 100;
  }

  .iconfreccia{
    width: 15%;

  }
  
  .titlecheckboxrotate1257 {
    margin-right: 20px;
    width: 70%;
    padding: 5px;
    padding-left: 10px;
  }

  .image1 {
    top: 0px;
    left: 0px;
    width: 319px;
    height: 41px;
    position: absolute;
    object-fit: cover;
    border-color: transparent;
    background-color: rgba(22, 26, 34, 1);
  }
  .text {
    top: 11px;
    left: 48px;
    color: rgba(255, 255, 255, 1);
    
    font-size: 13px;
    align-self: auto;
    font-style: normal;
    font-family: Nunito;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
  }
  .iconinfo522241 {
   
    box-sizing: border-box;
    border-color: transparent;
  }

 
  .svg {
    background-image: url("../../assets/ico/icona-info-circle.svg");
    /* position: absolute; */
    display: block;
    width: 24px;
    height: 24px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .image2 {
    width: 11px;
    height: 12px;
    margin-left: 6px;
    border-color: transparent;
  }
  .autorotate522251 {
    margin-top: 21px;
    margin-left: 22px;
    margin-bottom: 21px;
    /* width: 275px; */
    /* border: 2px dashed rgba(120, 120, 120, 0.4); */
    /* height: 69px; */
    /* display: flex; */
    /* position: absolute; */
    /* box-sizing: border-box; */
    align-items: flex-start;
    flex-shrink: 1;
    /* border-color: transparent; */
  }
  .fade-out {
    display: none;
  }
  .image3 {
    top: 16px;
    left: 22px;
    width: 13px;
    height: 8px;
    /* position: absolute; */
    object-fit: cover;
    border-color: transparent;
  }
  
  .image3-close {
    top: 16px;
    left: 22px;
    width: 13px;
    height: 8px;
    /* position: absolute; */
    /* object-fit: cover; */
    border-color: transparent;
  }