.sottocategorie29203 {
  width: 99%;
  /* min-height: 332px; */
  display: flex;
  position: inline;
  box-sizing: border-box;
  /* align-items: flex-start; */
  /* flex-shrink: 1; */
  border-color: transparent;
  background-color: #2c323f;
  margin: 0;
  padding: 0;
  color: white;
  text-align: left;
  max-height: calc(100vh - 115px);
  /* position: absolute; */
  overflow-y: scroll;
  z-index: 100;

}
.background-fade-out {
  min-height: 42px;
}

.image {
  top: 0px;
  left: 0px;
  width: 99%;
  /* height: 175px; */
  /* position: absolute; */
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10000000149011612);
  /* object-fit: cover; */
  /* border-color: transparent; */
  /* background-color: rgba(44, 50, 63, 1); */
}
.Frame1boxautorotate1783 {
  /* height: 140px; */
  /* display: flex; */
  /* position: absolute; */
  /* box-sizing: border-box; */
  /* align-items: flex-start; */
  /* flex-shrink: 1; */
  /* border-color: transparent; */
  width: 100%;
}


.titlecheckboxrotate1257 {
  z-index:100;
  width: 99%;
  height: 41px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  background-color: #0f1116;
}
.image1 {
  top: 0px;
  left: 0px;
  width: 319px;
  height: 41px;
  position: absolute;
  object-fit: cover;
  border-color: transparent;
  background-color: rgba(22, 26, 34, 1);
}
.text {
  top: 11px;
  left: 48px;
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  position: absolute;
  font-size: 13px;
  align-self: auto;
  font-style: normal;
  text-align: left;
  font-family: Nunito;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
}
.iconinfo522241 {
  top: 8px;
  right: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.svg {
  width: 24;
  height: 24;
  position: absolute;
}
.image2 {
  top: 5.599999904632568px;
  left: 6.400000095367432px;
  width: 11px;
  height: 12px;
  position: absolute;
  object-fit: cover;
  border-color: transparent;
}
.autorotate522251 {
  margin-top: 71px;
  margin-left: 22px;
  /* width: 275px; */
  /* border: 2px dashed rgba(120, 120, 120, 0.4); */
  /* height: 69px; */
  /* display: flex; */
  /* position: absolute; */
  /* box-sizing: border-box; */
  align-items: flex-start;
  flex-shrink: 1;
  /* border-color: transparent; */
}

.fade-out {
  display: none;
}

.image3 {
  top: 16px;
  left: 22px;
  width: 13px;
  height: 8px;
  position: absolute;
  object-fit: cover;
  border-color: transparent;
}

.image3-close {
  top: 16px;
  left: 22px;
  width: 13px;
  height: 8px;
  /* position: absolute; */
  /* object-fit: cover; */
  border-color: transparent;
}