.subtitle {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: white;

}

.container {
    width: 228px;
    /* height: 118px; */
    text-align: left;
}