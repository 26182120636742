.container {
    /* position: absolute; */
    width: 228px;
    height: 118px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, .2);
    margin-bottom: 20px;
    padding: 10px;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;

}

::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;

}

::-webkit-scrollbar-thumb:hover {
    background: #f2f2f2;
}