.model-container-edit {
  margin: 0 auto;
  /* display: absolute; */
  /* container */
  width: 1080px;
  min-height: 100%;
  padding: 0;
  margin: 0;
  background-size: 480px; 
  background-position-y: 200px;
  background-position-x: center;
  background-repeat: no-repeat;
}

/* model-viewer{
  width: 1080px;
} */


