.button{
    border: none;
    /* background-color: white; */
    position: relative;
    border-radius: 20px;
}

.buttonAR{
    border-radius: 5px;
    border-color: white;
    background-color: white;
    position: fixed;
    right: 0;
    top: 0;
    margin-top: 20px;
    margin-right: 20px;
}

.button-right-top{
    position: fixed;
    right: 0;
    top: 0;
    margin-top: 20px;
    margin-right: 20px;
    /* border-radius: 20px; */
}

.buttonARBottom{
    border-radius: 20px;
    background-color: white;
    position: fixed;
    right: 0;
    bottom: 0;
    margin-top: 20px;
    margin-right: 20px;
}


.button-right-bottom{
    position: fixed;
    right: 0;
    bottom: 0;
    margin-top: 20px;
    margin-right: 20px;
    /* border-radius: 20px; */
}