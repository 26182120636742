.hotspot-custom{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: black;
}

.hotspot-container{
    padding: 5px;
    width: max-content;
    overflow-y: scroll;
    overflow-x: auto;
    max-height: 400px;
    max-width: 400px;
    border-radius: 13px;
    background-color: white;
    margin-left: 35px;
}