p {
    margin: 0;
  }
  
  .headerProject {
    padding: 0px 1631px 31px 28px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    width: 1720px;
    height: 91px;
    
  }
  
  .spaarklyLogo1 {
    width: 59px;
    height: 59px;
    margin-top: 10px;
  }