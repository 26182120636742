.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-light{
  color: white;
}

.poster-img-preview{
  width: 200px;
  height: 200px;
  border-radius: 13.5px;
}

model-viewer{
  /* height: 700px;
  width: 700px; */
  /* max-width: 100%; */
  position: absolute;
  min-width: 100%;
  min-height: 100%;
}

#project {
  min-width: 100% !important;
  min-height: 80%  !important;
  position:relative;
  text-align: center;

}


#projectTwo {
  min-width: 80% !important;
  min-height: 100%  !important;
  position:relative;
  text-align: center;

}

@media only screen and (max-width: 980px) {
  #projectTwo {
    min-width: 70%!important;
    min-height: 100%!important;
    position:relative;
  }
}
.fixedPowered{
  position: fixed;
    z-index: 999;
    margin-left: calc(50% - 60px);
    margin-top: 10px;
}

/* #projectTwo {
  min-width: 80% !important;
  min-height: 100%  !important;
  position:relative;
} */
/* from doc hotspot*/

.dot{
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  background: #fff;
  --min-hotspot-opacity: 0;
}

.dim{
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Futura, Helvetica Neue, sans-serif;
  font-size: 18px;
  font-weight: 700;
  max-width: 128px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  width: max-content;
  height: max-content;
  transform: translate3d(-50%, -50%, 0);
  --min-hotspot-opacity: 0;
}

.show{
  --min-hotspot-opacity: 1;
}

.hide{
  display: none;
}
/* This keeps child nodes hidden while the element loads */
:not(:defined) > * {
  display: none;
}

.color {
  width: 50px;
  height: 50px;
  border-radius: 13px;
}

.picker1 {
  display: none;
}

.popup {
    z-index: 999;
}

.custom-layout{
  position: absolute;
  top:10px;  
  right: 101%;
  z-index: 999;
  width: 250px;
  height: 300px;
  padding: 16px;
  border-radius: 12px;
    background: #2C323F;
  box-shadow: 0 2px 2px #999;
}
.custom-layout .react-colorful {
 z-index: 999;
  margin: 0 auto;
}

.custom-layout .react-colorful__saturation {
  margin: 15px 0;
  border-radius: 5px;
  border-bottom: none;
  z-index: 999;

}

.custom-layout .react-colorful__hue {
  order: -1;
}

.custom-layout .react-colorful__hue,
.custom-layout .react-colorful__alpha {
  z-index: 999;

  height: 14px;
  border-radius: 5px;
}

.custom-layout .react-colorful__hue-pointer,
.custom-layout .react-colorful__alpha-pointer {
  z-index: 999;

  width: 20px;
  height: 20px;
}

.picker__swatches {
  display: flex;
  padding: 12px;
  flex-wrap: wrap;
}

.picker__swatch {
  width: 24px;
  height: 24px;
  margin: 4px;
  border: none;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.isBorder{
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: #2C323F;
}

.se-toolbar {display:block; position:relative; height:auto; width:100%; overflow:visible; padding:0; margin:0; background-color:#2c323f; outline:1px solid #dadada; z-index:5;}
