
  .inputNumberContainer{
    display: block;
    margin-bottom: auto;
    width: 30%;
  }

  .inputNumber{
    display: block;
    line-height: 27px;
    width: 71px;
    height: 27px;
    text-align: center;
    background: rgba(248, 245, 253, 0.2);
    border-radius: 13.5px;
    color: #fff;
  }

  .inputNumber{
    -webkit-appearance: none;
    border: none;
  }