.titoloProgetto {
  width: 271px;
  height: 85px;
}

.txt1064 {
  font-size: 62px;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  color: rgba(0, 211, 215, 1);
  width: 271px;
  word-wrap: break-word;
}

.projectListContainer {
  padding-left: 66px;
}

.elem1 {
  mix-blend-mode: normal;
  width: 338px;
  height: 204.63px;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 84px;
}

.group68 {
  padding: 19px;
  box-sizing: border-box;
  border-radius: 34px;
  border: 1px solid rgba(0, 0, 0, 1);
  width: 68px;
  height: 68px;
  background-color: #fff;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -34px;
  margin-left: -34px;
}

.group68:hover {
  background-color: rgba(0, 211, 215, 1);
}

.plus {
  width: 28px;
  height: 28px;
}
