.categorie1182 {
    width: 100%;
    position: absolute;
    min-height: calc(100vh - 100px);
    /* display: flex; */
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    background-color: #2C323F;
    padding-top: 10px;
  }
  

  p {
    margin: 0;
  }
  
  .flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .flex-hcenter {
    justify-content: center;
  }
  
  .save-_button {
    padding: 9px 11px 0px 11px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgba(128, 207, 0, 1);
    /* box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25); */
    width: 70px;
    height: 70px;
  }

  .export-_button {
    padding: 9px 4px 0px 6px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgba(0, 211, 215, 1);
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    width: 70px;
    height: 70px;
  }

  .row-element{
    display: flex;
    flex-direction: row;
    padding: 0px;
}

.colum-element{
  display: flex;
  flex-direction: column;
  padding: 5px;
}
  
  .icon-_save {
    width: 28.65px;
    height: 24px;
    margin-bottom: 4px;
  }
  
  .txt-959 {
    font-size: 14px;
    font-family: Nunito, sans-serif;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    width: 44px;
    height: 29px;
    word-wrap: break-word;
  }