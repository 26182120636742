.out {
  background: rgba(248, 245, 253, 0.2);;
  padding: 5px 16px;
  border-radius: 13.5px;
  color: #fff;
  width: 71px;
  height: 27px;
  left: 1634px;
  top: 764px;
}
