.canvas29985 {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}

.Group14291011 {
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  /* max-width: 1337px; */
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}

.image {
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  position: absolute;
  object-fit: cover;
  border-color: rgba(0, 0, 0, 0.30000001192092896);
  /* border-style: solid; */
  /* border-width: 1px;
  border-radius: 40px; */
  background-color: rgba(255, 255, 255, 1);
  background-size: cover;
}

.imageColum {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 752px;
  position: absolute;
  object-fit: cover;
  border-color: rgba(0, 0, 0, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 1);
  background-size:cover;
  display: flex;
  flex-direction: row;
}
