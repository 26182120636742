.posizionamentoTab {
    width: 100% - calc(16px);
    display: block;
    /* position:absolute;
    display: flex; */
    /* opacity: 0.30; */
    /* box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent; */
    align-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 0.25px solid #e7e7e7;
    border-top: 0.25px solid #e7e7e7;
    margin-left: 8px;
    margin-right: 8px;
  }

  .image01{
      width: 25px;
      height: 25px;
      align-items: center;
      display: block;
      margin: 0 auto;
      opacity: 0.6;
  }
  .textTab {
    color: rgba(255, 255, 255, 1);
   text-align: center;
    display: block;
    font-size: 14px;
    font-style: normal;
    font-family: Nunito;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    opacity: 0.6;

  }

  .posizionamentoTabActive{
    display: block;
    background-color:#FFFFFF33;
    align-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #e7e7e7;
    border-top: 0.5px solid #e7e7e7;
    margin-top: -1px;
    margin-bottom: -1px;
  }



  .posizionamentoTabActive .image01{
    opacity: 1;
}
.posizionamentoTabActive .textTab {
  opacity: 1;
}
  