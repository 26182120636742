@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap");

p {
  margin: 0;
}

.titoloProgetto {
  width: 271px;
  height: 85px;
}

.txt1064 {
  font-size: 62px;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  color: rgba(0, 211, 215, 1);
  width: 271px;
  word-wrap: break-word;
}

.projectListContainer {
  padding-left: 66px;
}

.flexGrid {
  display: flex;
  flex-wrap: wrap;
}

.image5 {
  top: -0.0000015856530206637132px;
  left: -36.2755126953125px;
  width: 36px;
  height: 36px;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
  border-color: transparent;
}
