.inputSliderContainer{
  display: block;
  margin-bottom: auto;
  width: 100%;
  margin-right: 20px;
}

input[type="range"] {
  -webkit-appearance: none;
  margin-right: 15px;
  width: 100%;
  height: 7px;
  background: rgba(255, 255, 255, 0.103);
  border-radius: 5px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.9),
    rgb(255, 255, 255, 0.9)
  );
  /* background-size: 70% 100%; */
  background-repeat: no-repeat;
  margin-bottom: auto;
  
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #ffffff;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #ffffff;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #ffffff;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: #ffffff;
}

input[type="range"]::-moz-range-thumb:hover {
  background: #ffffff;
}

input[type="range"]::-ms-thumb:hover {
  background: #ffffff;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
