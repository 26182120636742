p {
    margin: 0;
  }
  
  .headerProject {
    padding: 0px 0px 31px 28px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    width: 200%;
    height: 61px;
    display: flex;
    flex-wrap: wrap;
    background: rgb(65,177,172);
    background: linear-gradient(162deg, rgba(65,177,172,1) 0%, rgba(255,36,100,1) 100%);
  }
  
  .spaarklyLogo1 {
    width: 35px;
    /* height: 49px; */
    margin-top: 10px;
  }

  .titoloprogetto291004 {
    width: 600px;
    height: 44px;
    display: flex;
    top:20px;
    left:20px;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
  }
  .text {
    top: 0px;
    left: 51px;
    /* color: rgba(0, 211, 215, 1); */
    color: white;
    width: 600px;
    height: 46px;
    position: absolute;
    font-size: 26px;
    align-self: auto;
    font-style: normal;
    text-align: left;
    font-family: Nunito;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
  }

  .textName {
    top: 0px;
    color: #000;
    font-size: 32px;
    align-self: auto;
    font-style: normal;
    text-align: left;
    font-family: Nunito;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
  }
  .image {
    top: 10px;
    left: 6px;
    width: 26px;
    height: 16px;
    position: absolute;
   
    border-color: transparent;
  }

  .edittxt1291627 {
    width: 32px;
    height: 31.741071701049805px;
    opacity: 0.50;
    display: inline-block;
    box-sizing: border-box;
    /* background-color: rgba(255, 255, 255, 1); */
  }
  .svg {
    top: -8.375110626220703px;
    left: 8.571223258972168px;
    width: 14;
    height: 14;
    position: relative;
  }
  .image3 {
    top: 2.122117519378662px;
    left: 2.607302665710449px;
    width: 26px;
    height: 26px;
    position: relative;
    object-fit: cover;
    border-color: transparent;
  }