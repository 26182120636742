.container {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
}

.container-object {
  width: 90%;
  background: rgba(255, 255, 255, 0.1);
  text-align: left;
  border-radius: 13.5px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.active {
  background-color: aqua;
}

.title {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: white;
}

.icon {
  width: 25px;
  height: 25px;
  padding: 3px;
}

.delete-object {
  width: 10%;
}

.container-plus {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
}

.icon-plus {
  width: 10px;
  height: 10px;
  text-align: center;
}

.elem1 {
  border-radius: 50px;
  mix-blend-mode: normal;
  border-color: white;
  background-color: white;
  text-align: center;
  width: 20px;
  height: 20px;
  border: 2px solid;
}

.filter-selected {
  filter: invert(69%) sepia(79%) saturate(3216%) hue-rotate(136deg) brightness(101%) contrast(101%);
}
