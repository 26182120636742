.elem1 {
    mix-blend-mode: normal;
    width: 338px;
    height: 204.63px;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-color: #fff;
    margin-top:40px;
    margin-bottom: 40px;
  }

.project{
    width: auto;
    position:relative;
    margin-top:20px;
    margin-bottom: 20px;
    margin-right: 30px;
  }

  .model{
    width: 80%;
    height: 140px;
    border-radius: 20px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
    margin-top:10px;
    margin-bottom: 10px;
  }


  .model-img{
    background-size: cover;
    background-image: url("");
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }

  .model-image {
    width: 100%;
    height: 100%;
    box-shadow: 0px 10px 26px -4px rgba(0, 0, 0, 0.4000000059604645) ;
    object-fit: cover;
    border-color: transparent;
    border-radius: 20px;
    background-color: rgba(216, 206, 206, 1);
  }

  .model2{
    display: flex;
    position: absolute;
    height: 200px;
    width: 80%;
  }

  .project11142 {
    width: 392px;
    height: 223.32972717285156px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
  }
  .Group95291618 {
    top: 0px;
    left: 0px;
    width: 338px;
    height: 223px;
    display: flex;
    position: absolute;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
  }
  .Group14291619 {
    top: 0px;
    left: 0.00000476837158203125px;
    width: 338px;
    height: 205px;
    display: flex;
    position: absolute;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
  }
  .image {
    top: 0px;
    left: 0px;
    width: 338px;
    height: 205px;
    position: absolute;
    box-shadow: 0px 10px 26px -4px rgba(0, 0, 0, 0.4000000059604645) ;
    object-fit: cover;
    border-color: transparent;
    border-radius: 20px;
    background-color: rgba(216, 206, 206, 1);
  }
  .image1 {
   
    width: 338px;
    height: 205px;
    position: absolute;
    object-fit: cover;
    border-color: transparent;
    border-radius: 20px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
  }
  .image2 {
    top: 0px;
    width: 338px;
    height: 205px;
    opacity: 0.50;
    position: absolute;
    object-fit: cover;
    border-color: transparent;
  }
  .Group15291624 {
    top: 35px;
    left: 38.36767578125px;
    width: 280px;
    height: 49px;
    display: flex;
    position: absolute;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
  }
  .Group {
    display: flex;
    position: absolute;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
  }
  .text2 {
    padding: 20px;
    color: rgba(255, 255, 255, 1);
    height: 49px;
    position: absolute;
    font-size: 32px;
    align-self: auto;
    font-style: normal;
    text-align: left;
    font-family: Nunito;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
  }
  .text {
    top: 0px;
    left: 0px;
    color: rgba(255, 255, 255, 1);
    height: 49px;
    position: absolute;
    font-size: 32px;
    align-self: auto;
    font-style: normal;
    text-align: left;
    font-family: Nunito;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
  }
  .Group16291824 {
    top: 174px;
    left: 38px;
    width: 148px;
    height: 49px;
    display: flex;
    position: absolute;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
  }
  .text1 {
    top: 0px;
    left: 0px;
    color: rgba(255, 255, 255, 1);
    width: 148px;
    height: 49px;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: normal;
    text-align: left;
    font-family: Nunito;
    font-weight: 300;
    line-height: normal;
    font-stretch: normal;
  }
  .iconelementi291626 {
    top: 1.000056266784668px;
    left: 392.275634765625px;
    width: 199px;
    height: 36px;
    display: flex;
    position: absolute;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
  }
  .edittxt1291627 {
    top: 114.00005191921602px;
    left: -34.0082065822217px;
    width: 32px;
    height: 31.741071701049805px;
    display: flex;
    opacity: 0.50;
    position: absolute;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    background-color: rgba(255, 255, 255, 1);
  }
  .svg {
    top: 8.375110626220703px;
    left: 8.571223258972168px;
    width: 14;
    height: 14;
    position: absolute;
  }
  .svg2 {
    width: 24px;
    height: 24px;
    margin: 5px;
    filter: invert(77%) sepia(77%) saturate(1219%) hue-rotate(128deg) brightness(88%) contrast(91%);
  }
  .image3 {
    top: 2.122117519378662px;
    left: 2.607302665710449px;
    width: 26px;
    height: 26px;
    position: absolute;
    object-fit: cover;
    border-color: transparent;
  }
  .image4 {
    top: 166.99999852412202px;
    left: -33.764159826657306px;
    width: 31px;
    height: 32px;
    opacity: 0.50;
    position: absolute;
    object-fit: cover;
    border-color: transparent;
  }
  .image5 {
    top: -0.0000015856530206637132px;
    left: -36.2755126953125px;
    width: 36px;
    height: 36px;
    opacity: 0.50;
    position: absolute;
    object-fit: cover;
    border-color: transparent;
  }
  .image6 {
    top: 56.99994119388566px;
    left: -36.27556859262086px;
    width: 36px;
    height: 36px;
    opacity: 0.50;
    position: absolute;
    object-fit: cover;
    border-color: transparent;
  }